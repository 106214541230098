nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    bottom: 1rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blue(15px);
    transform: translateX(-50%);
    /* transform: translate3d(-50%, 75%, 0);
    transition: all 600ms ease; */
}

/* .nav_container:hover nav{
    transform: translate3d(-50%, 0, 0);
} */

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}

@media screen and (max-width: 600px) {
    nav {
        /* display: none; */
        gap: .4rem;
    }

}
