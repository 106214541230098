.experience_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    justify-items: center;
}

.experience_container > div {
    /* background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition); */
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px; /* Ensures all boxes have the same minimum height */
    width: 100%; /* Ensure full width within the grid columns */
}

.experience_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience_container > div h3 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 2rem;
}

.experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
}

.experience_details {
    display: flex;
    gap: 1rem;
}

.experience_details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

.experience_container > .experience_skills {
    grid-column: span 2; /* Span across both columns */
    text-align: center;
    width: 50%;
}

@media screen and (max-width: 1024px) {
    .experience_container {
        grid-template-columns: 1fr;
    }

    .experience_content > div {
        width: 100%;
        padding: 2rem;
        /* margin: 0 auto; */
    }

    .experience_content {
        padding: 1rem;
    }

    .experience_container > .experience_skills {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .experience_container {
        gap: 1rem;
    }   

    .experience_container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}